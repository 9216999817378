import * as React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import { PageProps } from "gatsby"

const PreviewPage = ({
  isPreview,
  isLoading,
  ...rest
}: {
  isPreview: boolean
  isLoading: boolean
} & PageProps) => {
  return (
    <>
      <h1 className="text-base">{isPreview ? `Loading...` : `No preview available.`}</h1>
    </>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
